import { Controller } from '@hotwired/stimulus'
import { visit } from "@hotwired/turbo"
import { format, subDays, differenceInDays, subYears } from 'date-fns'
import max from 'lodash/max'
import min from 'lodash/min'

export default class extends Controller {
  static targets = ['interval', 'from', 'appliedFrom', 'to', 'appliedTo', 'frame', 'compare', 'compareInterval', 'compareDates', 'compareFrom', 'compareTo', 'granularityDay', 'granularityWeek', 'granularityMonth', 'granularityQuarter', 'granularityYear' ]
  static values = { instanceId: Number }

  connect() {
    const url = new URL(window.location.href)

    if (url.search === '' && [20, 137, 159].includes(this.instanceIdValue)) {
      this.setInterval('last_two_weeks')
    } else if (url.search === '') {
        this.setInterval('last_four_weeks')
    } else if (url.searchParams.has('interval')) {
      this.setInterval(url.searchParams.get('interval'))
    } else {
      this.setFrom(url.searchParams.get('from'))
      this.setTo(url.searchParams.get('to'))
    }

    if (url.searchParams.has('compare_interval')) {
      this.setCompare(true)
      this.setCompareInterval(url.searchParams.get('compare_interval'))
    } else if (url.searchParams.has('compare_from')) {
      this.setCompare(true)
      this.setCompareFrom(url.searchParams.get('compare_from'))
      this.setCompareTo(url.searchParams.get('compare_to'))
    } else {
      this.setCompare(false)
      this.setCompareInterval('previous_period')
    }

    this.frameTargets.forEach(frame => frame.removeAttribute('disabled'))
    this.apply()
  }

  updateFrom() {
    this.setFrom(min([this.fromTarget.value, this.toTarget.value]))
    this.setInterval('')
  }

  updateTo() {
    this.setTo(max([this.toTarget.value, this.fromTarget.value]))
    this.setInterval('')
  }

  updateInterval() {
    this.setInterval(this.intervalTarget.value)
  }

  updateCompare() {
    this.setCompare(this.compareTarget.checked)
  }

  updateCompareInterval() {
    this.setCompareInterval(this.compareIntervalTarget.value)
  }

  updateCompareFrom() {
    this.setCompareFrom(min([this.compareFromTarget.value, this.compareToTarget.value]))
    this.setCompareInterval('')
  }

  updateCompareTo() {
    this.setCompareTo(max([this.compareToTarget.value, this.compareFromTarget.value]))
    this.setCompareInterval('')
  }

  setFrom(value) {
    this.fromTarget.value = value
  }

  setTo(value) {
    this.toTarget.value = value
  }

  setInterval(value) {
    this.intervalTarget.value = value
    if (value !== '') this.setDatesFromInterval()
    if (this.compareIntervalTarget.value !== '') this.setCompareDatesFromInterval()
  }

  setCompare(value) {
    this.compareTarget.checked = value
    if (value) {
      this.compareIntervalTarget.removeAttribute('disabled')
      this.compareDatesTarget.classList.remove('hidden')
    } else {
      this.compareIntervalTarget.setAttribute('disabled', true)
      this.compareDatesTarget.classList.add('hidden')
    }
  }

  setCompareFrom(value) {
    this.compareFromTarget.value = value
  }

  setCompareTo(value) {
    this.compareToTarget.value = value
  }

  setCompareInterval(value) {
    this.compareIntervalTarget.value = value
    if (value !== '') this.setCompareDatesFromInterval()
  }

  setDatesFromInterval() {
    const intervals = { 'last_week': 6, 'last_two_weeks': 13, 'last_four_weeks': 27, 'last_90_days': 90, 'last_365_days': 365 }
    const today = new Date(Date.now())
    let newEndDay = subDays(today, 1)
    let newStartDay = subDays(newEndDay, intervals[this.intervalTarget.value])
    this.setFrom(format(newStartDay, 'yyyy-MM-dd'))
    this.setTo(format(newEndDay, 'yyyy-MM-dd'))
  }

  setCompareDatesFromInterval() {
    let compareTo, compareFrom
    if (this.compareIntervalTarget.value === 'previous_period') {
      compareTo = subDays(this.from, 1)
      compareFrom = subDays(compareTo, differenceInDays(this.to, this.from))
    } else if (this.compareIntervalTarget.value === 'previous_year') {
      compareTo = subYears(this.to, 1)
      compareFrom = subYears(this.from, 1)
    }

    this.setCompareTo(format(compareTo, 'yyyy-MM-dd'))
    this.setCompareFrom(format(compareFrom, 'yyyy-MM-dd'))
  }

  apply() {
    this.appliedFromTarget.innerHTML = format(this.from, 'dd. MM. yyyy')
    this.appliedToTarget.innerHTML = format(this.to, 'dd. MM. yyyy')
    this.reloadFrames()
  }

  reloadFrames() {
    this.frameTargets.forEach(frame => {
      frame.setAttribute('src', this.frameUrl(frame.src))
      frame.reload()
    })
  }

  frameUrl(urlValue) {
    const url = new URL(urlValue)

    // main parameters
    url.searchParams.set('interval', this.intervalTarget.value)
    url.searchParams.set('from', this.fromTarget.value)
    url.searchParams.set('to', this.toTarget.value)

    // compare parameters
    if (this.compareTarget.checked) {
      url.searchParams.set('compare_interval', this.compareIntervalTarget.value)
      url.searchParams.set('compare_from', this.compareFromTarget.value)
      url.searchParams.set('compare_to', this.compareToTarget.value)
    } else {
      url.searchParams.delete('compare_interval')
      url.searchParams.delete('compare_from')
      url.searchParams.delete('compare_to')
    }

    return url
  }

  disableButton(button){
    button.disabled = true;
    button.classList.add("cursor-not-allowed", "bg-gray-300");
    button.classList.remove("hover:bg-gray-100");
  }

  reload(event) {
    const url = new URL(window.location.href)

    this.updateGranularityButtons(url)

    // main interval
    if (this.intervalTarget.value.length > 0) {
      url.searchParams.set('interval', this.intervalTarget.value)
      url.searchParams.delete('from')
      url.searchParams.delete('to')
    } else {
      url.searchParams.delete('interval')
      url.searchParams.set('from', this.fromTarget.value)
      url.searchParams.set('to', this.toTarget.value)
    }

    // compare interval
    if (this.compareTarget.checked) {
      if (this.compareIntervalTarget.value.length > 0) {
        url.searchParams.set('compare_interval', this.compareIntervalTarget.value)
        url.searchParams.delete('compare_from')
        url.searchParams.delete('compare_to')
      } else {
        url.searchParams.delete('compare_interval')
        url.searchParams.set('compare_from', this.compareFromTarget.value)
        url.searchParams.set('compare_to', this.compareToTarget.value)
      }
    } else {
      url.searchParams.delete('compare_interval')
      url.searchParams.delete('compare_from')
      url.searchParams.delete('compare_to')
    }
  
    if (event.target.classList.contains('granularity-btn')) {
      let granularityInterval = event.target.value
      if (url.searchParams.get('granularity_interval') === granularityInterval){
        url.searchParams.delete('granularity_interval')
      } else {
        url.searchParams.set('granularity_interval', granularityInterval)
      }
    }

    visit(url)
  }

  updateGranularityButtons(url){
    const minDate = new Date(this.from);
    const maxDate = new Date(this.to);

    let differenceInTime = maxDate.getTime() - minDate.getTime();
    let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    let granularityIntervalParam = url.searchParams.get('granularity_interval')

    if (granularityIntervalParam != null){
      if (granularityIntervalParam == 'week') {
        if (6 >= differenceInDays){
          url.searchParams.set('granularity_interval', 'day')
        }
      } else if (granularityIntervalParam == 'month') {
        if (28 >= differenceInDays){
          url.searchParams.set('granularity_interval', 'day')
        }
      } else if (granularityIntervalParam == 'quarter') {
        if (90 >= differenceInDays){
          url.searchParams.set('granularity_interval', 'week')
        }
      } else if (granularityIntervalParam == 'year') {
        if (365 >= differenceInDays){
          url.searchParams.set('granularity_interval', 'month')
        }
      }
    }
  }

  get from() {
    return new Date(this.fromTarget.value)
  }

  get to() {
    return new Date(this.toTarget.value)
  }
}