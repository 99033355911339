import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

export default class extends Controller {

  connect() {
    $('.basic-multiple-select-limited').select2({
      placeholder: "Select 2 competitors",
      debug: true,
      tags: true,
      maximumSelectionLength: 2
    }
  );
  }

  disconnect() {
    $('.basic-multiple-select-limited').select2('destroy')
  }
  
}
