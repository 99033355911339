import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ["form", "input"];
  static debounces = ["query"];

  connect() {
    useDebounce(this, { wait: 600 });
  }

  query() {
    this.submit();
  }

  submit() {
    let url = new URL(window.location.href);
    url.searchParams.delete('q');
    url.searchParams.append("q", this.inputTarget.value);

    this.formTarget.requestSubmit();
  }
}
